import React from "react";
import { withPrefix } from "gatsby";

interface Props {
  crossed?: boolean;
  iconUrl: string | undefined;
  iconTitle: string | undefined;
  iconSize?: "default" | "large";
}

const defaultProps: Props = {
  crossed: false,
  iconUrl: undefined,
  iconTitle: undefined,
  iconSize: "default",
};

const LargeIcons: React.FC<Props> = (props) => {
  const { crossed, iconUrl, iconTitle, iconSize } = props;
  let width = "32";
  const icon = iconUrl || "";
  if (icon.includes('icon-oz')) {
    width = "42";
  }
  if (icon.includes('icon-shield')) {
    width = "34";
  }
  if (icon.includes('icon-nitrogen')) {
    width = "28";
  }
  if (icon.includes('icon-document')) {
    width = "30";
  }
  if (icon.includes('icon-academic')) {
    width = "38";
  }

  return (
    <div
      className={`c-icon__image c-icon__image-size-${iconSize} uk-border-circle uk-width-auto uk-padding-remove uk-flex uk-flex-middle`}
    >
      {crossed && <div className="c-icon__crossed"></div>}
      {iconUrl && (
        <img
          className="uk-margin-auto c-filter__conex-green"
          width={iconSize === "default" ? width : "auto"}
          src={withPrefix(iconUrl)}
          alt={iconTitle}
        />
      )}
    </div>
  );
};

export default LargeIcons;

LargeIcons.defaultProps = defaultProps;
